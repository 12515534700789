import React from "react"

import Layout from "../components/layout"
import Services from "../components/marketing/services"
import SEO from "../components/seo"

const ServicesPage = (props) => (
  <Layout>
    <SEO title="Services" />
    <Services {...props}/>
  </Layout>
)

export default ServicesPage

import React, { Component } from "react";
import _ from "lodash";
import moment from 'moment-timezone'
import { Tabs, DatePicker, Collapse } from 'antd';
import { navigate } from 'gatsby'
import Geosuggest from 'react-geosuggest'
import axios from 'axios'
import ReactGA from 'react-ga'
import ReactModal from 'react-modal'
import Dropzone from 'react-dropzone'
// import DatePicker from "react-datepicker";

// import "react-datepicker/dist/react-datepicker.css";
import Header from "./header"
import BusinessForm from "./business-form"
import ServicesBanner from "../../images/services.jpg"
import phoneIcon from '../../images/phoneicon.svg'
import ServicesBannerNewyork from "../../images/services-newyork.jpg"
import GarbageTruck from "../../images/garbagetruck.jpg"
import WorkOne from '../../images/work1.svg'
import WorkTwo from '../../images/work2.svg'
import WorkThree from '../../images/work3.svg'
import RightSign from '../../images/rightsigncircle.svg'
import CrossCircle from '../../images/crosscircle.svg'
import dumpsterImgOne from '../../images/dumpsterimgone.svg'
import dumpsterImgTwo from '../../images/dumpsterimgtwo.svg'
import containerYard from '../../images/containeryard.svg'
import Containerimg from '../../images/container-img.svg'
import modalcloseIcon from "../../images/cancel.svg"
import ReviewIcon from '../../images/read-review-icon.svg'
import BackedIcon from '../../images/backed-icon.svg'
import config from "../config";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME
const dateFormat = 'MM/DD/YYYY';
const dateFormatWithTime = 'MM/DD/YYYY hh:mm A';
const LOGIN_URL = process.env.LOGIN_URL

const weekdays = new Array(7);
  weekdays[0] = "Sunday"
  weekdays[1] = "Monday"
  weekdays[2] = "Tuesday"
  weekdays[3] = "Wednesday"
  weekdays[4] = "Thursday"
  weekdays[5] = "Friday"
  weekdays[6] = "Saturday"

const timeArray =[
  { value: "6 AM - 9 AM", title: "6 AM - 9 AM" },
  { value: "9 AM - 12 PM", title: "9 AM - 12 PM" },
  { value: "12 PM - 3 PM", title: "12 PM - 3 PM" },
  { value: "3 PM - 6 PM", title: "3 PM - 6 PM" },
  { value: "After 6 PM", title: "After 6 PM" },
]

const containerImages = [
  { type: "10 Yard", imageUrl: "https://curbsidedriver-userfiles-mobilehub-1262477507.s3.amazonaws.com/public/curb_10_green.svg" },
  { type: "15 Yard", imageUrl: "https://curbsidedriver-userfiles-mobilehub-1262477507.s3.amazonaws.com/public/curb_15_green.svg" },
  { type: "20 Yard", imageUrl: "https://curbsidedriver-userfiles-mobilehub-1262477507.s3.amazonaws.com/public/curb_20_green.svg" },
  { type: "30 Yard", imageUrl: "https://curbsidedriver-userfiles-mobilehub-1262477507.s3.amazonaws.com/public/curb_30_green.svg" }
]

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().subtract(1, 'days')
}

function disabledDateToday(current) {
  const day = moment(current).format('dddd')
  // Can not select days before today and today
  return (current && current < moment()) || (day === 'Sunday')
}

function getTime(s) {
  const a = [
    {key: '09', value: '09'},
    {key: '10', value: '10'},
    {key: '11', value: '11'},
    {key: '12', value: '12'},
    {key: '13', value: '1'},
    {key: '14', value: '2'},
    {key: '15', value: '3'},
    {key: '16', value: '4'},
    {key: '17', value: '5'},
    {key: '18', value: '6'}
  ]
  const sfind = _.find(a, (ai) => {
    return ai.key === s
  })
  if(sfind) {
    return sfind.value
  }
  
}

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

function disabledHours() {
  const r = range(0, 12).splice(6, 3)
  return r
}
const checkZip = data => {
  return fetch(`${HOSTNAME}/api/sessions/checkzip?zip=${data.zipcode}`, {
    method: 'GET'
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}

class Servicespage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zipcodeError: false,
      containerSize : "",
      length: 0,
      width: 0,
      hight: 0,
      err: {},
      tab: 'dumpster',
      tons: null,
      live_load_yard: '',
      requestedTime: '',
      requestedDate: '',
      deliveryday: '',
      email: '',
      containerlocation: '',
      placement: '',
      otherPlacement: '',
      streetaddress: "",
      address: '',
      neighborhood: "",
      borough: "",
      city: "",
      zipcode: "",
      state: "",
      lat: 0,
      lng: 0,
      addressDisabled: true,
      cityname: "New York",
      place: "New York",
      cityForPhone: '',
      containerInfo: "yes",
      timeList: [],
      availableTimeList: {time: []},
      selectedContainerImageUrl: "",
      company_zipcode: null,
      company_selected_date: null,
      zipcode_data: [],
      is_get_rates_btn_disabled: false
    };
    this.addImages = this.addImages.bind(this)
    this.timer = null;
  }

  componentDidMount = async() => {
    await this.getCompany()
    const searchParam = (_.get(this.props, 'location.search', '')).replace('?=', '').trim()
    const gclid = _.get(this.props.location, 'search', '').replace('?gclid=', '')
    if(gclid && gclid !== '') {
      localStorage.setItem('gclid', gclid)
    } else {
      localStorage.removeItem('gclid')
    }
    if(searchParam === "?type=garbage"){
        this.setState({ tab: "Live Load"})
    } else if(searchParam === "?type=dumpster") {
      this.setState({ tab: "dumpster"})
    } else if(searchParam && searchParam.length && gclid === '') {
      navigate(`/curbside-now/quote/${searchParam}`)
    }
    let url = window && window.location.pathname.split('/')
    this.setState({ url: window && window.location.pathname })
    if(url.length === 2 || url.length === 3 || url.length === 4 || url.length === 5) {
      let p = url[2] === "new-york" ? "NewYork" : url[2]
      if(p === undefined) {
        p = ""
      }
      this.setState({ cityForPhone: p })
      // const fetchInfo = () => axios.get(`${HOSTNAME}/api/sessions/getZipData?place=${p}`)
      // const res = await fetchInfo()
     
      // if(res && res.data && res.data.data) {
      //   const allData = _.cloneDeep(res.data.data)
      //   let arr = _.get(res.data, 'data', [])
      //   arr = _.filter(arr, (td) => {
      //     return String(td.companyId) === "5efec049ade0a54ed6dfa5fe"
      //   })
      //   let obj = {}
      //   const half = parseInt(arr.length/2)
      //   var b = arr.splice(0,half);
      //   var a = arr
      //   const shalf = parseInt(b.length/2)
      //   var c = b.splice(0,shalf);
      //   const thalf = parseInt(a.length/2)
      //   var d = a.splice(0,thalf);
      //   obj = {
      //     a: c,
      //     b: b,
      //     c: d,
      //     d: a
      //   }
      //   if((url.length === 4 || url.length === 5 ) && url[3] !== "") {

      //     let selected = _.find(allData, (r) => {
      //       return _.lowerCase(r.cityname).replace(/\s/g, '') === url[3] && _.lowerCase(url[3]).replace(/\s/g, '')
      //     })
      //     if(selected) {
      //       this.setState({ cityname:  _.get(selected, 'cityname', ''), selected, place:_.get(selected, 'place', '') })
      //     } else {
      //       this.setState({ cityname: _.get(res.data, 'data[0].place', ''), place: _.get(res.data, 'data[0].place', '')})
      //     }
      //   }
      //   else {
      //     this.setState({ cityname: _.get(res.data, 'data[0].place', ''), place: _.get(res.data, 'data[0].place', '')})
      //   }
      //   if(p === "") {
      //     this.setState({ cityname: "New York", place: "New York"})
      //   }
      //   this.setState({ cityListing: obj })
      // }

      // const fetchInfoCalendar = () => axios.get(`${HOSTNAME}/api/sessions/getFreeTime`)
      // const res2 = await fetchInfoCalendar()
      // if(res2 && res2.data && res2.data.data) {
      //   console.log(res2)
      //   this.setState({ timeList: res2.data.data})
      // }
    }

    // (function () {
    //   var d = document;
    //   var s = d.createElement("script");
    //   s.type = "text/javascript"
    //   //s.src = "https://maps.googleapis.com/maps/api/place/autocomplete/json?key=AIzaSyDC4hfVQUrN7Vc45Wh0Qpx4cpxs1YPWE24&libraries=places";
    //   s.src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDC4hfVQUrN7Vc45Wh0Qpx4cpxs1YPWE24&libraries=places"
    //   // d.getElementsByTagName.appendChild(s);
    //   d.getElementsByTagName("head")[0].appendChild(s);
    //   //d.getElementById("calendly1")[0].appendChild(s);
    // })();

    const self = this
      setTimeout(function(){
      if (window.google){
        self.setState({ load: true },()=>{
          self.forceUpdate()
        })
      }
    }, 600);

    if (_.get(this.props, 'location.state.tab', '')) {
      let containerSize = ''
      if (_.get(this.props, 'location.state.tab', '') === "Live Load") {
        containerSize = "Live Load"
      }
      this.setState({ tab: _.get(this.props, 'location.state.tab', ''), containerSize })
    }
  }

  update() {
    this.componentDidMount()
  }

  async getCompany () {
    try {
      const {data} = await axios.get(`https://alpha-api.curbwaste.com/api/companies/ecommerce/${config.COMPANY_ID}`)
      console.log(data, "<<<data")
      if(data && (data.statusCode === 200 || data.type ==="success")){
        localStorage.setItem('company_token', data.data.token)
        localStorage.setItem('company_merchant_id', data.data.merchantId)
      }
    } catch (error) {
      console.log(error, "<<<error")
    }
  }

  getRates () {
    this.setState({is_get_rates_btn_disabled: true})
     let isError = false
     console.log(this.state.company_zipcode , ">>>>this.state.company_zipcode")
     console.log(this.state.zipcode_data , ">>>>this.state.zipcode_data")
     console.log(this.state.company_zipcode , ">>>>this.state.company_selected_date ")
    if(!this.state.company_zipcode || this.state.company_zipcode.length <= 0){
      isError = true
      this.setState({err : {company_zipcode: "Service Location Required."}, is_get_rates_btn_disabled: false}, () => {
        console.log(this.state.err, "<<<err1")
      })
    }
    else if(!this.state.zipcode_data || this.state.zipcode_data.length <= 0){
      isError = true
      this.setState({err : {company_zipcode: "We're sorry, we do not provide service in this zip code"}, is_get_rates_btn_disabled: false}, () => {
        console.log(this.state.err, "<<<err2")
      })
    } 
    else if(!this.state.company_selected_date || this.state.company_selected_date.length <= 0){
      isError = true
      this.setState({err : {company_selected_date: "Service Date Required."}, is_get_rates_btn_disabled: false}, () => {
        console.log(this.state.err, "<<<err2")
      })
    }
    console.log(isError, "<<<isError")
    if(!isError){
      window.location.href = `https://alpha-ecommerce.curbwaste.com/product?referrer=${config.COMPANY_ID}&zipcode=${this.state.company_zipcode}&date=${this.state.company_selected_date}`
    }
  }

  getRatesButtonDisable () {
    return (this.state.company_selected_date && (this.state.company_selected_date.trim().length <= 0 || this.state.company_zipcode.trim().length <= 0))
  }

  async onChangeZipcode (company_zipcode) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState({err: {}})
    this.timer = setTimeout(async () => {
      const { data } = await axios.get(`https://alpha-api.curbwaste.com/api/companies/service-zipcodes?mode=AUTOCOMPLETE&q=${company_zipcode}`, {
      headers: {companyId: config.COMPANY_ID}
    });
      
      if (data && (data.statusCode === 200 || data.type ==="success")) {
        this.setState({zipcode_data: data.data})
      }
    }, 500);
    this.setState({ company_zipcode });

  }

  changeTab (tab) {
    let containerSize = ''
    this.setState({ tab },()=>{
      if (this.state.tab === "Live Load") {
        containerSize = "Live Load"
      }
      this.setState({ containerSize, live_load_yard: ''  })
    })
  }

  handleChange (e) {
    let length = 0
    let width = 0
    let hight = 0
    const image = _.find(containerImages, (c) => {
      return String(c.type) === String(e.target.value)
    })
    if (image) {
      this.setState({ selectedContainerImageUrl: image.imageUrl })
    }
    // if (e.target.value === "10 Yard") {
    //   length = 11
    //   width = 8
    //   hight = 4
    // }
    // if (e.target.value === "15 Yard") {
    //   length = 16
    //   width = 8
    //   hight = 4
    // }
    // if (e.target.value === "20 Yard") {
    //   length = 22
    //   width = 8
    //   hight = 4
    // }
    // if (e.target.value === "30 Yard") {
    //   length = 22
    //   width = 8
    //   hight = 6
    // }

    if(e.target.value) {
      const err = this.state.err
      err[e.target.name] = ''
      this.setState({ err })
    }
    this.setState({ [e.target.name]: e.target.value, hight, width, length },()=>{

    })
  }

  onDateChange(date, dateString) {
    if (date && dateString) {
      this.setState({err: {}})
      console.log(date._d.getDay(), "<<<day")
      let deliveryday = this.getDay(date._d.getDay())
      const err = this.state.err
      err.requestedDate = ''
      this.setState({ err, requestedDate: dateString, deliveryday, company_selected_date: moment(dateString).format("MM-DD-YYYY") },()=>{
      })
    }
  }

  onDateChangeVideo(date, dateString) {
    if (date && dateString) {
      // const t = parseInt(moment(time).format('hh'))
      const err = this.state.err
      err.selectedDate = ''
      
      this.setState({ err, selectedDate: dateString },()=>{
      })
    }
  }
  
  selectDateVideo(time) {
    const t = parseInt(moment(time).format('hh'))
    const am = document.getElementsByClassName("ant-picker-time-panel-column")[2].childNodes
    if(t >= 9 && t <= 12) {
      am[1].style.display = "none"
      am[1].classList.remove('ant-picker-time-panel-cell-selected')
      am[0].classList.add('ant-picker-time-panel-cell-selected')
      am[0].style.display = ""
      this.setState({selectedDate: `${moment(time).format('MM/DD/YYYY hh:mm')} AM`})
    } else {
      am[0].classList.remove('ant-picker-time-panel-cell-selected')
      am[1].classList.add('ant-picker-time-panel-cell-selected')
      am[0].style.display = "none"
      am[1].style.display = "" 
      this.setState({selectedDate: `${moment(time).format('MM/DD/YYYY hh:mm')} PM`})     
    }
  }

  onDateChangeforMobile (e) {
    if (e.target.value) {
      var d = new Date( e.target.value );
      let date = moment(d).format("MM/DD/YYYY")
      let day = d.getDay();
      let deliveryday = this.getDay(day)
      const err = this.state.err
      err.requestedDate = ''
      this.setState({ err, requestedDate: date, deliveryday },()=>{
      })
    } else {
      this.setState({ requestedDate: '', deliveryday: '' },()=>{
      })
    }
  }
  getDay(day){
    return weekdays[day];
  }

  onChange(e) {
    if(e.target.value) {
      const err = this.state.err
      err[e.target.name] = ''
      this.setState({ err })
    }
    this.setState({ [e.target.name]: e.target.value },()=>[
    ])
  }

  onChangeAddress(value) {
    this.setState({ streetaddress: '' })
  }

  getTons () {
    let tons = null
    if (this.state.containerSize === "10 Yard") {
      tons = 2
    }
    if (this.state.containerSize === "15 Yard") {
      tons = 3
    }
    if (this.state.containerSize === "20 Yard") {
      tons = 4
    }
    if (this.state.containerSize === "30 Yard") {
      tons = 6
    }
    this.setState({ tons })
    return tons
  }

  validate() {
    let errObj = {}
    const { email, requestedDate, requestedTime, live_load_yard, placement, otherPlacement, addressDisabled, zipcodeError, tab, containerSize, containerlocation } = this.state
    let scrollID = ''
    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) { // eslint-disable-line
        errObj.email = true
      }
    }
    if(addressDisabled === true || zipcodeError) {
      errObj.address = true
      scrollID = scrollID === '' ? 'address' : scrollID
    }
    if(tab === 'dumpster') {
      if(!containerSize || containerSize === '') {
        errObj.containerSize = true
        scrollID = scrollID === '' ? 'containerSize' : scrollID
      }
      if(!placement || placement === '') {
        errObj.placement = true
        scrollID = scrollID === '' ? 'placement' : scrollID
      }
    } else {
      if(!live_load_yard || live_load_yard == '') {
        errObj.live_load_yard = true
        scrollID = scrollID === '' ? 'live_load_yard' : scrollID
      }
      if(!placement || placement === '') {
        errObj.placement = true
        scrollID = scrollID === '' ? 'placement' : scrollID
      }
    }
    if(placement === 'On Street' && _.get(this.state, 'containerlocation', '') === '' ) {
      errObj.containerlocation = true
      scrollID = scrollID === '' ? 'containerlocation' : scrollID
    }
    if(placement === 'Other' && _.get(this.state, 'otherPlacement', '') === '' ) {
      errObj.otherPlacement = true
      scrollID = scrollID === '' ? 'otherPlacement' : scrollID
    }
    if(!requestedDate || requestedDate === '') {
      errObj.requestedDate = true
      scrollID = scrollID === '' ? 'requestedDate' : scrollID
    }
    if(!requestedTime || requestedTime === '') {
      errObj.requestedTime = true
      scrollID = scrollID === '' ? 'requestedTime' : scrollID
    }
    if(email === "") {
      errObj.email = true
      scrollID = scrollID === '' ? 'email' : scrollID
    }
    this.setState({ err: errObj, scrollID },()=>{
      this.forceUpdate()
    })
  }
  validateWalk() {
    let errObj = {}
    const { email, requestedDate, selectedDate, requestedTime, live_load_yard, placement, otherPlacement, addressDisabled, zipcodeError, tab, containerSize, containerlocation } = this.state
    let scrollID = ''
    if (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) { // eslint-disable-line
        errObj.email = true
      }
    }
    if(addressDisabled === true || zipcodeError) {
      errObj.address = true
      scrollID = scrollID === '' ? 'address' : scrollID
    }
    if(!selectedDate || selectedDate === '') {
      errObj.selectedDate = true
      scrollID = scrollID === '' ? 'selectedDate' : scrollID
    }
    if(email === "") {
      errObj.email = true
      scrollID = scrollID === '' ? 'email' : scrollID
    }
    this.setState({ err: errObj, scrollID },()=>{
      this.forceUpdate()
    })
  }
  randomString16(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  handleEventCall = ()=> {
    if(process.env.REACT_APP_GA) {
    ReactGA.pageview(ReactGA.pageview(_.get(this.props, 'location.pathname', '/')))
      ReactGA.event({
        category: 'Home inquiries',
        action: 'Home inquiries'
      })
    }
  }
  handleEventCallPhone() {
    if(process.env.REACT_APP_GA) {
      ReactGA.pageview(ReactGA.pageview(_.get(this.props, 'location.pathname', '/')))
      ReactGA.event({
        category: 'Clicks to the phone number',
        action: 'Clicks to the phone number'
      })
    }
  }
  getPrice = async ()=> {
    if(this.state.containerInfo !== "no") {
    await this.validate()
    let randomString = await this.randomString()
    const { err, scrollID } = this.state
    var btnSubmitTags = document.getElementById('getaprice' );
    // btnSubmitTags.click();
    if(Object.keys(err).length === 0) {
      this.handleEventCall()
      const { containerSize, tab, live_load_yard, requestedDate, requestedTime, deliveryday, email, zipcode, containerlocation, placement, otherPlacement,
        address, streetaddress, city, state, county, lat, lng, borough, neighborhood,
      } = this.state
      let gclid = localStorage.getItem('gclid')
      gclid = gclid && gclid !== '' ? gclid : ''
      let dataRedirect = {
        gclid,
        tab,
        containerSize, live_load_yard, tons: this.getTons(), requestedDate, requestedTime, deliveryday, email, zipcode, containerlocation, placement, otherPlacement,
        address, streetaddress, city, state, county, lat, lng, borough, neighborhood, email, randomString
      }
      const mailCredential = {
        date_of_submission: new Date(),
        address: address,
        email: email.toLowerCase(),
        gclid,
        requestedDate: requestedDate,
        url: String(document.location),
        type: "home",
        randomString,
        dataState: dataRedirect,
        quoteUrl: `https://mycurbside.com/curbside-now?=${randomString}`
      }
      await fetch(`${HOSTNAME}/api/quotes/insertHomeCustomer`,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mailCredential)
      }).then((res) =>  {
        return res.json()
      }).then((data) => {
        if(data.error) {
          if(data && data.error && data.error.statusCode && data.error.statusCode === 400 ){
            this.setState({ openModal: true })
          } else {
            // message.error(err && err.message ? err.message : "")
            console.log(err.message, "<<<error ")
          }
        } else {
          btnSubmitTags.click();
          navigate(`/curbside-now/quote/${randomString}`,
          {
            state: {
              data: dataRedirect,
            },
          }
        );
        }
      }).catch((err) => {
        // message.error(err && err.message ? err.message : "")
        console.log(err.message, "<<<message")
      });
    } else {
      const element = document.getElementById(`${scrollID}`)
      if(element) {
        const elemHeight = document.getElementById(`${scrollID}`).offsetTop;
        window.scroll(0,
          elemHeight);
      }
    }
  } else {
    await this.validateWalk()
    const obj = {
      date: moment(this.state.selectedDate).toISOString(),
      dateString: moment(this.state.selectedDate).format('MM/DD/YYYY HH:mm'),
      time: this.state.selectedTime,
      address: this.state.address,
      email: this.state.email,
      image: this.state.image,
      timezone: moment.tz.guess()
    }
    try {
      if(Object.keys(this.state.err).length === 0) {
        await fetch(`${HOSTNAME}/api/sessions/addWalkThrough`,{
          method:'POST',
          headers:{
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(obj)
        }).then((res) =>  {
          return res.json()
        }).then((data) => {
          if(data.statusCode === 500) {
            this.setState({errTimes : data.data})
          } else {
            this.setState({ errTimes: [], containerInfo:"yes", openInfoModal: true, email: "", image: "", showImage: "", selectDate: "", selectTime: "", address: ""})
          }
        })
      }
  } catch(e) {
    console.log(e)
  }
  }
  }

  randomString() {
    const length = 6
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  closeModal() {
    this.setState({ openModal: false, openInfoModal:false })
  }
  buttonDisabled (){
    let disable = true
    const { containerSize, containerInfo, selectedDate, requestedDate, requestedTime, live_load_yard, zipcode, placement, otherPlacement, containerlocation, addressDisabled, zipcodeError, email } = this.state
    if(containerInfo === 'no') {
      if(email && email !== "" && selectedDate && selectedDate !== '' && addressDisabled !== true && !zipcodeError) {
        disable = false
      }
      return disable
    } else {
      if (containerSize && containerSize === "Live Load") {
        if (live_load_yard && containerSize && requestedDate && requestedTime && zipcode &&  placement && !zipcodeError && email) {
          disable = false
        }
      } else {
        if (containerSize && requestedDate && requestedTime && zipcode && placement && !zipcodeError && email) {
          disable = false
        }
      }
      if (placement && placement === "Other") {
        if (!otherPlacement) {
          disable = true
        }
      }
      if (placement && placement === "On Street") {
        if (!containerlocation) {
          disable = true
        }
      }
      if(addressDisabled === true) {
        disable = true
      }
      return disable
    } 
    
  }

  topScroll () {
    window && window.scrollTo(0,0)
  }

  onSuggestSelect(suggest) {
    let state = ''
    let city = ''
    let zipcode = ''
    let streetaddress = ''
    let country = ''
    let streetNo = ''
    let displayaddress = ""
    let neighborhood = ""
    let borough = ""
    let lat = 0
    let lng = 0
    let address= '' //eslint-disable-line
    if (suggest) {
      if (suggest.location.lat && suggest.location.lng) {
        lat = suggest.location.lat
        lng = suggest.location.lng
      }
     // address = suggest.description
      suggest.gmaps.address_components.forEach(function (element) {
       // displayaddress = suggest.gmaps.formatted_address.replace(', USA', '')
      element.types.forEach(function (type) {
        switch (type) {
          case 'administrative_area_level_1':
            state = element.short_name;
            break;
          case 'postal_code':
            zipcode = element.long_name;
            break;
          case "locality":
            city = element.long_name;
            break;
          case 'street_number':
            streetNo = element.long_name;
            break;
          case 'country':
            country = element.short_name;
            break;
          case 'sublocality_level_1':
            borough = element.long_name;
            break;
          case 'neighborhood':
            neighborhood = element.long_name;
            break;
          default:
          break;
        }
       }, this)
      }, this)
      if(zipcode) {
       fetch(`${HOSTNAME}/api/sessions/checkzip?zip=${zipcode}`,{
         method:'GET'
       }).then((res) =>  {
         return res.json()
       }).then((data) => {
         if(data.error) {
           this.setState({ errModal: true, addressDisabled: true, zipcodeError: true })
         } else {
           const err = this.state.err
           err.address = ''
           let fullAddress = suggest.gmaps.formatted_address
           let addArr = [];
           let streetAdd = [];
           addArr = fullAddress.split(', ')
           streetAdd = suggest.description.split(', ')
           if(streetAdd.length > 0) {
             streetaddress = streetAdd[0].trim()
           }
           if (city === "") {
             if(addArr.length > 0) {
               if(addArr.length === 4) {
                 city = addArr[1]
               } else if(addArr.length === 5) {
                 city = addArr[2]
               }
             }
           }
           displayaddress = streetaddress ? `${streetaddress}, ` : ''
           displayaddress += city ? `${city}, ` : ''
           displayaddress += state ? `${state} ` : ''
           displayaddress += zipcode ? `${zipcode}` : ''
           this.setState({addressDisabled: false, zipcodeError: false, err, zoom: 15, addressDisabled: false, county: _.get(data, 'data[0].place'), address: displayaddress, streetNo, streetaddress, displayaddress, city, state, zipcode, country, lat, lng, borough, neighborhood },()=>{
             // this.getStateValuesforHeader()
           })
         }
       })
     }
    }
  }
  onSuggestNoResults(userInput) {
  //console.log('onSuggestNoResults for :' + userInput); // eslint-disable-line
  }

  minDateForMobile(){
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth()+1; //January is 0!
    var yyyy = today.getFullYear();
    if(dd<10){
          dd='0'+dd
      }
      if(mm<10){
          mm='0'+mm
      }
    today = yyyy+'-'+mm+'-'+dd;
    return today
  }
  _onFocus(e){
    e.target.type = "date";
  }
  _onBlur(e){
      if (this.state.requestedDate){
        e.target.type = "date";
      } else {
        e.target.type = "text";
      }
      e.currentTarget.placeholder = "When would you like your dumpster dropped off?";
  }
  getPriceGa() {
    console.log('event---')
  }
  scrollTo() {
    window.scrollTo({top: 1200});
  }
  updateContainerInfo(type) {
    this.setState({ containerInfo: type})
  }
  addImages(files) {
    const _URL = window.URL || window.webkitURL
    const image = []
    files.forEach(file => {
      if (file.type.split('/')[0] === 'image') {
        const img = new Image()
        const self = this
        const reader = new FileReader()
        reader.onloadend = () => {
          img.src = _URL.createObjectURL(file)
          img.onload = function () {
            /* eslint-disable */
            const name = file.name ? file.name.replace(/-|\s/g, '-').trim() : Math.floor(Math.random() * 100)
            image.push({ name, imagePreviewUrl: reader.result, imgIndex: image.length })
            if (image.length === 1) {
              self.setState({ image: image[0], showImage:  reader.result})
            } else {
              self.setState({ images: [] })
            }
          }
        }
        reader.readAsDataURL(file)
      } else {
        /* eslint-disable */
        alert('File Type Invalid')
      }
    })
  }
  selectDate(e) {
    const availableTimeList = _.find(this.state.timeList , (t) => {
      return t.date === e.target.value
    })
    this.setState({ selectedDate: e.target.value, availableTimeList})
  }
  selectTime(e) {
    this.setState({ selectedTime: e.target.value})
  }
  render() {
    const { cityname, cityListing, selected, err, place, url, selectedContainerImageUrl } = this.state
    if(url === "/curbside-now/new-york" || url === "/curbside-now/new-york/") {
      document.title = "New York City Debris Removal | Curbside"
    }
    return (
      <div>
        <Header/>
        <section className="aboutus__section businessbanner2">
          {/* <img src={ServicesBanner} alt="" /> */}
        { this.state.tab === "dumpster" ?
          <img src={ cityname === "New York" || cityname === "Brooklyn" ? ServicesBannerNewyork : ServicesBanner} alt="" />
          :
          <img src={GarbageTruck} alt="" />
        }
        </section>
        <div className="clearfix"/>
        <section className="services__section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <div className="right__section order-sm-12">
                  <div>
                        {this.state.containerInfo !== "no" ? 
                        <div>      
                           <div className="form-group" id="email">
                          <label htmlFor="email" className="services__label">Service Location *</label>
                          <input
                            name="company_zipcode"
                            value={this.state.company_zipcode}
                            onChange={(e) => this.onChangeZipcode(e.target.value)}
                            type="text" placeholder="Zip Code..."
                            className={err.company_zipcode ? "services__input err--border" : "services__input"}
                            autoCapitalize="none" />
                            <span className="errors"> {err && err.company_zipcode ? err.company_zipcode : ""}</span>
                            {/* <span className="errors"> {err && err.email ? err.email : ""}</span> */}
                        </div>
                        <div className="form-group" id="requestedDate">
                          <label htmlFor="requestedDate" className="services__label">Select Service Date *</label>
                          {/* <input type="text" onFocus={this._onFocus.bind(this)} onBlur={this._onBlur.bind(this)} onChange={this.onDateChangeforMobile.bind(this)} placeholder="When would you like your dumpster dropped off?" min={this.minDateForMobile()}
                            className={err.requestedDate ? "services__input tabletviews err--border" : "services__input tabletviews"} /> */}
                          <DatePicker
                            disabledDate={disabledDate}
                            allowClear={false}
                            className={err.requestedDate ? "services__input err--border hhh" : "services__input hhh"}
                            placeholder={_.get(this.state, 'containerSize', '') !== "Live Load" ? "When would you like your dumpster dropped off?" : "When would you like your material picked up?"}
                            // value={this.state.requestedDate ? moment(this.state.requestedDate) : ""}
                            onChange={this.onDateChange.bind(this)}
                            format={dateFormat}
                             />
                          <span className="errors"> {err && err.company_selected_date ? err.company_selected_date : ""}</span>
                        </div>
                      
                        </div>  : "" }
                       
                        <div className="form-group">
                          <label htmlFor="gclid" name="gclid" className="services__label"></label>
                          <input
                            type="hidden"
                            className="services__input"
                            name="gclid"
                          />
                        </div>
                        {this.state.errTimes && this.state.errTimes.length !== 0 ?
                        <div>
                        Unfortunately the time you selected is not available anymore. Here are some other times we are available:
                          <ul className="list-ol">
                          {_.get(this.state, 'errTimes', []).map((c, i)=>{
                            let s = c.split(":")
                             return (
                            <li>{getTime(s[0])}:00</li>
                             )
                          })}
                          </ul>
                        </div> : "" }
                        <button
                          type="button"
                          onClick={this.getRates.bind(this)}
                          disabled={this.getRatesButtonDisable() || this.state.is_get_rates_btn_disabled}
                          className={(this.getRatesButtonDisable() || this.state.is_get_rates_btn_disabled) ?  "btn btn__dark disabled yellow__btn" : " yellow__btn btn btn__dark"}
                        >
                          {this.state.is_get_rates_btn_disabled ? "Please wait...": "Get Rates"}
                        </button>

                      {/* </TabPane>
                      <TabPane tab="Business" key="2">
                        <BusinessForm /> */}
                        {/* <div className="form-group">
                          <label className="services__label">What’s the name of your company?</label>
                          <input type="text" placeholder="Enter your comapany name" className="services__input" />
                        </div>
                        <div className="form-group">
                          <label className="services__label">What’s your name?</label>
                          <input type="text" placeholder="Enter your first and last name" className="services__input" />
                        </div>
                        <div className="form-group">
                          <label className="services__label">What number can we reach you at?</label>
                          <input type="text" placeholder="Enter your phone number" className="services__input" />
                        </div>
                        <div className="form-group">
                          <label className="services__label">What’s your email address?</label>
                          <input type="text" placeholder="Enter your email address" className="services__input" />
                        </div>
                        <div className="form-group">
                          <label className="services__label">Where is your company located?</label>
                          <input type="text" placeholder="Enter your company address" className="services__input" />
                        </div>
                        <button className="btn btn__dark">Submit</button> */}
                      {/* </TabPane>
                    </Tabs> */}
                  </div>
                  <span onClick={this.getPriceGa.bind(this)} className="display-none" id="getaprice">Price_Form</span>
                  <div className="phone-service">
                    <h5>Or speak to an expert</h5>
                    <span><img src={phoneIcon} alt="" />
                    <span onClick={this.handleEventCallPhone.bind(this)}>
                      {_.get(this.state, 'cityForPhone', '') === "nassau" ?
                        <a href="tel:5162662033">516-266-2033 </a>
                        : <a href="tel:7183846357">718-384-6357 </a>
                      }
                      </span>
                    </span>
                  </div>

                </div>


                <div className="left__section order-sm-1">
                  <h1>{url === "/curbside-now/new-york" || url === "/curbside-now/new-york/" ? "New York City" : cityname} Dumpster Delivery</h1>
                  <div className="review-head" onClick={this.scrollTo.bind(this)}>
                    <h3><img src={ReviewIcon} alt="" />Read Reviews</h3>
                  </div>
                  {selected ? <h6>Serving this zipcodes in {url === "/curbside-now/new-york" || url === "/curbside-now/new-york/" ? "New York City" : cityname}, New York : {_.get(selected, 'zipcodes', []).join(', ')} </h6> : "" }
                  {this.state.tab === "dumpster" ?
                  <p>
                    Are you moving or renovating and in need of a dumpster? Look no further than Curbside. Curbside will provide you
                    with a debris container whether you are looking for it for a day or extended time. We make it very easy to book
                    and manage your container through our customer portal. Our clients are industry leaders and no matter how big or
                    small we make sure to get you the service you need.
                  </p> :
                  <p>Sometimes you just have a mess of loose debris that you need to get removed as soon as possible. Curbside offers debris truck service to make sure you get your material removed as quickly and efficiently as possible. Simply enter your approximate amount of yards (to the best of your ability) and we will do the rest!</p>
                  }
                  <ul>
                    <li>Friendly 24/7 customer service.</li>
                    <li>Affordable, upfront pricing .</li>
                    <li>No time windows, book when you want.</li>
                  </ul>

                  <h4>Most Popular Questions</h4>
                  <Collapse
                    accordion
                    bordered={false}
                    showArrow={false}
                    className="faq-list"
                  >
                    {/* <Panel
                      header="Does my bin have to be completely full before you will pick it up?"
                      key="1"
                    >
                      <p></p>
                    </Panel>
                    <Panel
                      header="When do I have to pay? "
                      key="2"
                    >
                      <p></p>
                    </Panel>
                    <Panel
                      header="How do I schedule a pickup?"
                      key="3"
                    >
                      <p></p>
                    </Panel> */}
                    <Panel
                      header="Are there any materials that are not allowed in the container?"
                      key="4"
                    >
                      <p>No 55 gallon drums empty or full, car batteries, asbestos or asbestos containing products, hazardous materials (liquids such as paint, oil, solvent, pesticides, etc.), CFC containing appliances (such as air conditioners, refrigerators, freezers, de-humidifiers, etc.), and no car tires are allowed.</p>
                    </Panel>
                    <Panel
                      header="How do street permits work?"
                      key="5"
                    >
                      <p>When ordering a container that will go on any street within any of the 5 boroughs, it is required that we have a permit issued by the DOT. The permit will cost the client $50 plus tax and is valid for up to 5 days (including weekends).</p>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </section>


        <div className="clearfix" />

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="backed-list">
                  <div className="backed-list-wrapper">
                  <img src={BackedIcon} alt="" />
                  <h2>Backed by the Curbside Price Guarantee</h2>
                  <h4>Find a better price and we’ll match it. <a href="/priceguarantee"><span>Learn more about our price guarantee</span></a></h4>
                  </div>
                  <ul>
                    <li>
                      <h3>Chris W.</h3>
                      <h6>Booked a 10 yard container</h6>
                      <img src={ReviewIcon} alt="" />
                      <p>Simple process. Found them online and was able to book the dumpster delivery seamlessly and without confusion. Best part of the experience was being able to watch the truck on the way. Highly recommend. </p>
                    </li>
                    <li>
                      <h3>Ava</h3>
                      <h6>Booked a live load </h6>
                      <img src={ReviewIcon} alt="" />
                      <p>We had been putting off cleaning out our basement but with the pandemic and being at the house more than ever we finally decided to get our basement back. We had no idea how much material we had but fortunately Curbside had an option for a video conference. The team was so helpful and helped me rest easy knowing we were getting the best price. </p>
                    </li>
                    <li>
                      <h3>William S.</h3>
                      <h6>Booked a 30 yard container</h6>
                      <img src={ReviewIcon} alt="" />
                      <p>Our contracting business requires us to find the best pricing available while also receiving timely service. The construction process is time and budget sensitive and Curbside goes above and beyond making sure we understand our costs, sustainability metrics and most optimal times for delivery and removal. They are truly ahead of the rest. </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="clearfix" />
        
        <section className="graybg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>How Curbside Works</h2>
                <p>Get Started in three easy steps. We have the technology to keep things as efficient and sustainable as possible to help your project run smoothly.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul>
                  <li>
                    <img src={WorkOne} alt="" />
                    <h5>Select & Schedule Service</h5>
                    <p>Select the day and time for your debris removal and get instant, affordable pricing. </p>
                  </li>
                  <li>
                    <img src={WorkTwo} alt="" />
                    <h5>Manage Everything Online</h5>
                    <p>Removals, contacting the Curbside team, and viewing orders are a tap away. </p>
                  </li>
                  <li>
                    <img src={WorkThree} alt="" />
                    <h5>Sit Back and Relax</h5>
                    <p>The Curbside driver will pickup and drop off your removal on time.</p>
                  </li>
                </ul>
                <button onClick={this.topScroll.bind(this)} className="btn btn-darkbtn yellowbtn">Order Now</button>
              </div>
            </div>
          </div>
        </section>

        <section className="neighbourhoods">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h1>Top Neighborhoods in {url === "/curbside-now/new-york" || url === "/curbside-now/new-york/" ? "New York City" : place} for Dumpster Delivery</h1>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <ul>
                  {_.get(cityListing, 'a', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? "new-york" : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
                <ul>
                  {_.get(cityListing, 'b', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? "new-york" : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
                <ul>
                  {_.get(cityListing, 'c', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? "new-york" : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
                <ul>
                  {_.get(cityListing, 'd', []).map((c, i)=>{
                    return (
                      <li key={i} onClick={this.update.bind(this)}><a href={`/curbside-now/${_.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'') == "newyork" ? "new-york" : _.lowerCase(c.place.replace(/\s/g, '')).replace(/\s/g,'')}/${_.lowerCase(c.cityname.replace(/\s/g, '')).replace(/\s/g,'')}`}>{_.get(c, 'cityname', '')}</a></li>
                    )
                  })}
                </ul>
              </div>
            </div>

          </div>
          <ReactModal
            isOpen={this.state.openModal}
            onRequestClose={this.closeModal.bind(this)}
            contentLabel=""
            ariaHideApp={false}
            className="paymentlogin"
            >
              <div className="react-modal-dialog react-modal-dialog-400 react-modal-dialog-centered">
                <div className="react-modal-header">
                  {/* <h5 className="react-modal-title"></h5> */}
                <button type="button" className="btn react-modal-close" onClick={this.closeModal.bind(this)}><img src={modalcloseIcon} alt="" /></button>
                </div>
                <div className="react-modal-body">
                  <div className="wrapps">
                    <h4>You already have an account, please login to you portal to place an order.</h4>
                    <a href={LOGIN_URL} target="_blank"><button className="login-btn">Login</button></a>
                  </div>
                </div>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={this.state.openInfoModal}
            onRequestClose={this.closeModal.bind(this)}
            contentLabel=""
            ariaHideApp={false}
            className="paymentlogin"
            >
              <div className="react-modal-dialog react-modal-dialog-400 react-modal-dialog-centered submitted-modal">
                <div className="react-modal-header">
                  {/* <h5 className="react-modal-title"></h5> */}
                <button type="button" className="btn react-modal-close"><img src={modalcloseIcon} alt="" /></button>
                </div>
                <div className="react-modal-body">
                  <div className="wrapps">
                    <img src={RightSign}/>
                    <h1>Your request has been submitted.</h1>
                    <img src={CrossCircle} onClick={this.closeModal.bind(this)}/>
                  </div>
                </div>
            </div>
          </ReactModal>
        </section>
      </div>
    );
  }
}

export default Servicespage;
